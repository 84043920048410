$(function(){
	var filter = '*';
	var search = '';
	var $grid = $('.grid');
	var headerPosition = $('main').position().top;
	var hasSearched = false;
	var hasFiltered = false;
	var loadedFromHashTrackingOverride = false;

	$grid.find('select, input').on('change keyup search', filterGrid);
	$grid.find('form').on('submit', function(){ return false; });

	if(window.location.hash){
		var filterMatches = window.location.hash.match(/filter=(.+)/i);
		if( filterMatches.length ){
			var filter = filterMatches[1];
			$grid.find('select option').each(function(){
				if( $(this).text() == filter ){
					$(this).parents('select').val($(this).val());
					loadedFromHashTrackingOverride = true; //skip tracking this instance of the filtering mechanism
					filterGrid();
					return;
				}
			});
		}
	}

	function filterGrid() {
		getFilter();
		getSearch()

		loadedFromHashTrackingOverride = false;

		if( $(window).scrollTop() > headerPosition ){
			$('body, html').animate({
				scrollTop: headerPosition
			}, 1000);
		}
		$grid.find('.listing li').hide().filter(filter).show().each(function(){
			if( search == '' ){
				return;
			}
			if( $(this).find('h2').text().toLowerCase().search(search) < 0 && $(this).find('p').text().toLowerCase().search(search) < 0 ){
				$(this).hide();
			}
		});
	}

	function getFilter(){
		filter = $grid.find('select').val();
		if( filter == null ){
			filter = '*';
		}
		//track if it's the first time filtering
		if( !loadedFromHashTrackingOverride && !hasFiltered && filter != '*' ){
			hasFiltered = true;
			ga('send','event','Grid Filter','filter',filter);
		}
		window.location.hash = 'filter='+$grid.find('select option[value="'+filter+'"]').text();
	}

	function getSearch(){
		search = $grid.find('input').val().toLowerCase();
		//track if it's the first time searching
		if( !hasSearched && search != '' && search != undefined ){
			hasSearched = true;
			ga('send','event','Grid Filter','search');
		}
	}

	$('.download').on('click',function(){
		ga('send', 'event', 'Roadmaps', 'download', $(this).attr('data-tracking-name'), parseInt($(this).attr('data-tracking-id'),10));
	});
});