(function($){
	$(function(){
		$('.grid header').scrollToFixed();

		$('select').wrap('<label class="styled-select"></label>').parent().append('<button>'+$(this).find('option').eq(0).text()+'</button>');

		$('.styled-select').find('select').on('change', function(){
			var label = $(this).find('option[value="'+$(this).val()+'"]').text();
			$(this).next('button').text(label);
		});

		setTimeout(function(){
			if( !localStorage.getItem('FT_surveyPromptHidden') ){
				$('#survey-promo').slideDown(1000);
			}
		}, 1000);

		$('#hide-survey-prompt').on('click', function(){
			$('#survey-promo').slideUp(function(){ $('#survey-promo').remove(); });
			localStorage.setItem('FT_surveyPromptHidden', true);
		});
	});
})(jQuery);