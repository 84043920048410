(function($){
	$(function(){
		var $tabs = $('.tabs header li');
		$tabs.on('click',function(){
			$tabs.removeClass('active');
			$(this).addClass('active');
			ga('send', 'event', 'Tabs', 'switch', $(this).text(), parseInt($('main').attr('data-tracking-id'),10));
			$('dl dd').hide().eq($tabs.index($(this))).show();
		});

		$('#print').on('click',function(){
			ga('send', 'event', $('main').attr('data-tracking-type'), 'print', $('main').attr('data-tracking-name'), parseInt($('main').attr('data-tracking-id'),10));
			window.print();
		});
	});
})(jQuery);