(function($){
	$(function(){
		var $contactForm = $('#contact-form');
		$contactForm.on('submit',function(e){
			e.preventDefault();
			$.post('/contact', $(this).serialize()).done(function(data){
				ga('send', 'event', 'Forms', 'submit', 'Contact Form');
				$contactForm.find('.error').remove();
				$contactForm.append('<p class="success message">'+data.message+'</p>');
				$contactForm.find('input, textarea').not('[type=submit]').val('');
			}).fail(function(data){
				$contactForm.append('<p class="error message">'+data.message+'</p>');
			});
			return false;
		});
	});
})(jQuery);