var participatingSchools;

function initmap() {
	$.getJSON(API_BASE + SCHOOL_LISTING_PATH).done(function(data){
		participatingSchools = data;
		console.log(participatingSchools);
		buildMap();
	}).fail(function(){
		console.log('error loading JSON');
	});
}

function buildMap(){
	var map = new google.maps.Map(document.getElementById('map-frame'), {
		center: {lat: 40.2672, lng: -86.1349},
		scrollwheel: false,
		// zoom: 8,
		mapTypeControl:false,
		streetViewControl: false,
		styles: [
			{
				"featureType": "administrative",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "administrative",
				"elementType": "geometry.fill",
				"stylers": [
					{
						"color": "#c62929"
					}
				]
			},
			{
				"featureType": "administrative.country",
				"elementType": "geometry.stroke",
				"stylers": [
					{
						"visibility": "on"
					},
					{
						"weight": "0.50"
					}
				]
			},
			{
				"featureType": "administrative.province",
				"elementType": "geometry.stroke",
				"stylers": [
					{
						"visibility": "on"
					},
					{
						"color": "#098692" //state outline
					},
					{
						"weight": "0.50"
					}
				]
			},
			{
				"featureType": "administrative.locality",
				"elementType": "geometry.stroke",
				"stylers": [
					{
						"visibility": "on"
					},
					{
						"color": "#098692"
					},
					{
						"weight": "0.50"
					}
				]
			},
			{
				"featureType": "landscape",
				"elementType": "all",
				"stylers": [
					{
						"color": "#aee55e"
					}
				]
			},
			{
				"featureType": "landscape",
				"elementType": "geometry.fill",
				"stylers": [
					{
						"color": "#BBCEA1" //ground fill
					}
				]
			},
			{
				"featureType": "landscape.natural.terrain",
				"elementType": "all",
				"stylers": [
					{
						"color": "#ffffff"
					}
				]
			},
			{
				"featureType": "landscape.natural.terrain",
				"elementType": "geometry.fill",
				"stylers": [
					{
						"gamma": "6.59"
					},
					{
						"lightness": "48"
					}
				]
			},
			{
				"featureType": "poi",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "poi.park",
				"elementType": "geometry.fill",
				"stylers": [
					{
						"color": "#00840a"
					}
				]
			},
			{
				"featureType": "road",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "transit",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "transit.line",
				"elementType": "geometry",
				"stylers": [
					{
						"lightness": 700
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "all",
				"stylers": [
					{
						"color": "#60afd5"
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "geometry.fill",
				"stylers": [
					{
						"color": "#098692" //water fill
					},
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "labels",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			}
		]
	});

	var infowindow = new google.maps.InfoWindow();
	var markers = [];
	var bounds = new google.maps.LatLngBounds();

	//TODO set this up so that the pins drop when you scroll down far enough on the homepage
	for( var i = 0; i < participatingSchools.length; i++){
		var school = participatingSchools[i];

		var schoolLatLng = {lat: parseFloat(school.latitude), lng: parseFloat(school.longitude)};
		var marker = new google.maps.Marker({
			position: schoolLatLng,
			map: map,
			title: school.name,
			info: infowindow
		});

		bounds.extend(marker.getPosition());

		markers.push(marker);

		google.maps.event.addListener(marker, 'click', function() {
			this.info.setContent(this.title);
			this.info.open(map, this);
		});
	}

	// map.fitBounds( bounds ); //just the schools
	map.fitBounds( new google.maps.LatLngBounds( new google.maps.LatLng(37.647811, -88.203735), new google.maps.LatLng(41.798832, -84.638672) ) ); //all indiana
}
