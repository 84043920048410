$(function(){
	var zipErrorShown = false; //prevents repeated alerts and, thus, blurs on the school zip field

	//do an AJAX search on the zip code to pull out city & state automatically
	$('#school-zip').on('blur', lookupZipCode).on('change keyup',function(){
		$('#school-city').val('');
		$('#school-state').val('');
		$('#school-citystate-preview').text('');

		if( $(this).val() == '' ){
			$(this).attr('data-state','pristine')
		} else {
			$(this).attr('data-state','dirty');
			if( $(this).val().length == 5 || $(this).val().length == 10 ){
				lookupZipCode();
			}
		}
	});

	function lookupZipCode(){
		var $schoolZip = $('#school-zip');
		var zip = $schoolZip.val();
		if( $schoolZip.attr('data-state') == 'pristine' || zip == '' ){
			return;
		}

		if( zip.match(/[\d]{5}/) == null && zip.match(/[\d]{5}-[\d]{4}/) == null ){
			alert('Please provide a valid Zip Code');
			return;
		}

		$schoolZip.removeClass('error');
		$.getJSON('//api.zippopotam.us/us/'+zip,function(data){
			var city = data.places[0]['place name'];
			var state = data.places[0]['state abbreviation'];
			$('#school-city').val(city);
			$('#school-state').val(state);
			$('#school-citystate-preview').text(city + ', ' + state);
			$schoolZip.attr('data-state','pristine');
			zipErrorShown = false;
		}).fail(function(){
			$schoolZip.addClass('error');
			if( !zipErrorShown ){
				zipErrorShown = true;
				alert('Error locating Zip Code. Please type your Zip Code again.');
			}
		});
	}

	//do an AJAX search on the school search
	$('#school-query').on('keyup',function(){
		var query = $(this).val();
		if( query.length < 3 ){
			return;
		}
		var uri = API_BASE + SCHOOL_SEARCH_PATH + '/' + query;
		$.getJSON(uri, function(data){
			var schools = [];
			for( var i = 0; i < data.length; i++){
				var schoolLi = '<li data-id="'+data[i].id+'" data-name="'+data[i].name+'"><b>'+data[i].name+'</b>';
				if( !isNullorUndefined(data[i].city) && data[i].city.trim() != '' ){
					schoolLi = schoolLi + ' <address>'+data[i].city+', '+data[i].state+'</address>';
				}
				schoolLi = schoolLi + '</li>';
				schools.push( schoolLi );
			}
			$('#ajax-schools').empty().append(schools.join(''));
		}).fail(function(){
			alert('Error loading schools. Please try again.');
		});

	}).on('focus click',function(e){
		e.stopPropagation();
		$('#ajax-schools').show();
	});

	$('body').on('click',function(){
		$('#ajax-schools').hide();
	});

	$('#school-query').on('search',function(){ //onsearch event includes clicking the "clear" "x" button
		$('#ajax-schools').hide();
		clearAJAXSelectedSchool();
	});

	function clearAJAXSelectedSchool(){
		$('#school-id').val(null);
		$('#school-name-ajax-placholder').val(null);
	}

	//set the school ID when selecting from the ajax dropdown
	//TODO do this on page load as well in case they've returned to the page
	$('#ajax-schools').on('click','li',function(e){
		e.stopPropagation();
		var schoolID = $(this).attr('data-id');
		var schoolName = $(this).attr('data-name');
		$('#school-id').val(schoolID);
		$('#school-query, #school-name-ajax-placholder').val(schoolName);
		$('#ajax-schools').hide();
	});

	//hide/show school entry variations based on the checkbox
	$('#add-school').find('input').on('change',function(){
		if( $(this).is(':checked') ){
			$('.ajax-search').find('input').removeClass('required').removeAttr('required');
			$('#manual-school-entry').show().find('input').not('#school-address2').addClass('required').attr('required','required');
			$('#automatic-school-entry').hide();
		} else {
			$('.ajax-search').find('input').addClass('required').attr('required','required');
			$('#manual-school-entry').hide().find('input').removeClass('required').removeAttr('required');
			$('#automatic-school-entry').show();
		}
	});

	//got to check multiple events here for responsiveness
	$('.number').find('input').on('input change keyup',function(){
		var $label = $(this).next('span');
		var type = ( $(this).val() == 1 ) ? 'singular' : 'plural';
		$label.text( $label.attr('data-'+type) );
	});

	$('#form-register').on('submit', function(){
		ga('send', 'event', 'Forms', 'submit', 'Registration Form');
		return validateRegistrationForm();
	});

	$('fieldset').on('focus click keyup', 'input.invalid', function(){
		$(this).removeClass('invalid');
	});

	$('fieldset').on('change', 'ul.invalid input', function(){
		$(this).parents('ul').removeClass('invalid');
	});

	function validateRegistrationForm(){
		$('.invalid').removeClass('invalid');

		var inputsValid = true;
		$('input.required').each(function(){
			if( $(this).val() == '' || isNullorUndefined( $(this).val() ) ){
				$(this).addClass('invalid');
				inputsValid = false;
				scrollToElement($(this));
				return false;
			}
		});

		if( !inputsValid ){
			return false;
		}

		var groupsValid = true;
		$('.required-group').each(function(){
			if( $(this).find(':checked').length < 1 ){
				$(this).addClass('invalid');
				groupsValid = false;
				scrollToElement($(this));
				return false;
			}
		});

		if( !groupsValid ){
			return false;
		}

		return true;

	}

	function isNullorUndefined( value ){
		return ( value == null || typeof value === 'undefined');
	}

	function init() {
		var preSelectedSchool = $('#school-name-ajax-placholder').val();
		if( preSelectedSchool ){
			$('#school-query').val(preSelectedSchool);
		}
	}

	function scrollToElement($element){
		$('html, body').animate({
			scrollTop: $element.offset().top - 60
		}, 1000);
	}

	init();

});